import React, { useState, useEffect } from "react";
import "./App.css";
import "./ResponseStyles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [emails, setEmails] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [responses, setResponses] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [senderIds, setSenderIds] = useState([]);
  const [selectedSenderId, setSelectedSenderId] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    fetchSenderIds();
  }, []);

  const clearConsole = async () => {
    setResponses([]);
    setTemplateId("");
    setEmails("");
    setSelectedSenderId("");
  };

  function areAllInputsFilled(formId) {
    const inputs = document.querySelectorAll("input, select");

    for (const input of inputs) {
      if (!input.checkValidity()) {
        return false;
      }
    }

    return true;
  }

  const fetchSenderIds = async () => {
    try {
      const response = await fetch(`${apiUrl}/get-sender-ids`); // Replace with your backend API endpoint
      if (response.ok) {
        const data = await response.json();
        setSenderIds(data);
      } else {
        console.error("Error fetching sender IDs:", response.status);
      }
    } catch (error) {
      console.error("Error fetching sender IDs:", error);
    }
  };

  const handleSendClick = async () => {
    setResponses([]);
    if (areAllInputsFilled("myForm")) {
      setValidationMessage("");
      const response = await fetch(`${apiUrl}/send-emails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emails, templateId, selectedSenderId }),
      });

      if (response.status === 200) {
        toast.success("Request succeeded!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        const data = await response.json(); // Parse the response body as JSON
        setResponses(data);
      } else {
        toast.error("Request failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      setValidationMessage("Please fill in all fields and select a sender ID.");
    }
  };

  return (
    <div className="App">
      <div>
        <h1>Email Sender</h1>
        <label>Emails (comma-separated):</label>
        <input
          type="text"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          required
        />
        <br />
        <label>Template ID:</label>
        <input
          type="text"
          value={templateId}
          onChange={(e) => setTemplateId(e.target.value)}
          required
        />
        <br />
        <br />

        <label htmlFor="senderId">Sender ID</label>
        <select
          id="senderId"
          value={selectedSenderId}
          onChange={(e) => setSelectedSenderId(e.target.value)}
          required
        >
          <option value="">Select a sender ID</option>
          {senderIds.map((senderId) => (
            <option key={senderId} value={senderId}>
              {senderId}
            </option>
          ))}
        </select>

        <br />
        <br />
        <br />

        <button onClick={handleSendClick}>Send</button>
        <br />
        <br />
        {validationMessage && (
          <p className="validation-message" style={{ color: "red" }}>
            {validationMessage}
          </p>
        )}

        {responses && responses.length > 0 ? (
          <>
            <button onClick={clearConsole}>Clear</button>
            <br /> <br />
            <br />
          </>
        ) : (
          <></>
        )}

        <div className="responses">
          {responses.map((item, index) => (
            <div
              key={index}
              className={`response-item ${
                item.error ? "response-error" : "response-success"
              }`}
            >
              <p>Email: {item.email}</p>
              {item.response ? (
                <p>Response: {item.response.message}</p>
              ) : (
                <p>Error: {item.error.message}</p>
              )}
            </div>
          ))}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
