// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS to your existing styles or create a new CSS file */

/* Style for the container holding responses */
.response-container {
    margin-top: 20px;
  }
  .response-container {
    max-height: 400px; /* Set an appropriate maximum height */
    overflow-y: auto;
  }
  
  
  /* Style for each response item */
  .response-item {
    border: 1px solid #e0e0e0;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  /* Hover effect for each response item */
  .response-item:hover {
    transform: translateY(-5px);
  }
  
  /* Style for response success message */
  .response-success {
    color: #4caf50;
    font-weight: bold;
  }
  
  /* Style for response error message */
  .response-error {
    color: #f44336;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/ResponseStyles.css"],"names":[],"mappings":"AAAA,kEAAkE;;AAElE,8CAA8C;AAC9C;IACI,gBAAgB;EAClB;EACA;IACE,iBAAiB,EAAE,sCAAsC;IACzD,gBAAgB;EAClB;;;EAGA,iCAAiC;EACjC;IACE,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,0CAA0C;IAC1C,sCAAsC;EACxC;;EAEA,wCAAwC;EACxC;IACE,2BAA2B;EAC7B;;EAEA,uCAAuC;EACvC;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA,qCAAqC;EACrC;IACE,cAAc;IACd,iBAAiB;EACnB","sourcesContent":["/* Add this CSS to your existing styles or create a new CSS file */\n\n/* Style for the container holding responses */\n.response-container {\n    margin-top: 20px;\n  }\n  .response-container {\n    max-height: 400px; /* Set an appropriate maximum height */\n    overflow-y: auto;\n  }\n  \n  \n  /* Style for each response item */\n  .response-item {\n    border: 1px solid #e0e0e0;\n    padding: 15px;\n    margin-bottom: 15px;\n    border-radius: 8px;\n    background-color: #ffffff;\n    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s ease-in-out;\n  }\n  \n  /* Hover effect for each response item */\n  .response-item:hover {\n    transform: translateY(-5px);\n  }\n  \n  /* Style for response success message */\n  .response-success {\n    color: #4caf50;\n    font-weight: bold;\n  }\n  \n  /* Style for response error message */\n  .response-error {\n    color: #f44336;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
